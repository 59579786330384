import React from 'react'
import Link from 'next/link'
import YoutubePlayer from '../YoutubePlayer'
import { Container } from '../Container'

const MainHero = () => {
  return (
    <Container>
      <div className="w-full rounded-lg radius-lg 2xl" style={{backgroundImage: "url('/images/bg/src5.jpg')", backgroundPosition: "center", backgroundSize: "cover" }}>
        <div className='w-full rounded-lg' style={{ backdropFilter: 'grayscale(.5) blur(5px) brightness(.5)' }}>
            <div className='flex flex-row gap-2 w-full rounded-lg p-2'>
              <div className="flex flex-col lg:flex-row backdrop:blur-lg 2xl p-2 rounded-lg w-2/5">
                <div className='flex flex-col justify-evenly gap-2'>
                  <span className="text-4xl max-md:text-4xl font-bold">HumanityRP Sezon 12!</span>
                  <span>
                    Witaj w 12 sezonie HumanityRP! Przeżyj niezapomniane przygody na Deerisle.<br/>
                    Odkryj tajemnice Sakhal i stwórz własną historię.<br/>
                    Eksploruj rozległe tereny, poluj na zwierzynę i przetrwaj w dziczy.<br/>
                    Dwa światy, nieskończone możliwości - czekają na Ciebie.
                  </span>
                  <div className='flex flex-col gap-2'>
                    <h2 className="text-1xl max-md:text-lg font-bold">Dołącz do nas i zostań częścią tej niezwykłej społeczności!</h2>
                    <div className="flex gap-2">
                      <Link
                        href='https://humanityrp.pl/application'
                        target='_blank'
                        className="btn btn-sx btn-primary self-start rounded-lg">
                        Aplikacja whitelist
                      </Link>
                      <Link
                        href='https://discord.com/invite/humanityrp'
                        target='_blank'
                        className="btn btn-sx btn-primary self-start rounded-lg">
                        Discord
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-center w-3/5'>
                <YoutubePlayer videoId="Q7QlwIMBPtk" />
              </div>
            </div>
        </div>
      </div>
    </Container>
  )
}

export default MainHero